<template>
  <div class="me">
    <div class="spin">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
</template>

<script>
import TokenService from "@/services/token.js";
export default {
  name: "PubView",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    exit() {
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    }
  },
  created() {
    let srv = new TokenService("public");
    srv
      .get(this.id)
      .then((entry) => {
        if (entry) {
          let expires_at = new Date();
          // begin: test
          //entry.access_timeout = 0.3;
          // end: test
          expires_at.setTime(
            expires_at.getTime() + entry.access_timeout * 60 * 1000
          );
          localStorage.setItem("redirect_url", entry.redirect_url || "/");
          this.$cookies.set("access_token", entry.reference, expires_at);
          this.$router.push(
            `/dashboard/equipment/${entry.connector_id}/${entry.screen_id}`
          );
          setTimeout(() => {
            this.$cookies.set("access_token", entry.reference, expires_at);
          }, 5000);
        } else {
          this.exit();
        }
      })
      .catch((entry) => {
        // begin test
        // entry.redirect_url = "https://example.com";
        // end test
        if (entry && entry?.redirect_url) {
          document.location.href = entry.redirect_url;
        } else {
          this.exit();
        }
      });
  }
};
</script>

<style scoped>
.me {
  display: table;
  height: 80vh;
  width: 100vw;
  vertical-align: middle;
  text-align: center;
  color: #999;
  font-size: 22pt;
}
.spin {
  display: table-cell;
  vertical-align: middle;
}
</style>
